import React from "react";
import "./HiFraserGroupBlock.scss";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";

export const HiFraserGroupBlock = ({ heading, business }) => {
  return (
    <section className="hif-group-block layout">
      <div className="hif-group-block__wrapper">
        {heading && <h2 className="hif-group-block__heading">{heading}</h2>}

        <ul className="hif-group-block__table">
          {Object.keys(business).length > 0 &&
            business?.map((item, index) => {
              return (
                <li key={index} className="hif-group-block__table__item">
                  <div className="hif-group-block__table__item-image">
                    <ImagePass src={item.logo} />
                  </div>
                  <p>{item?.description}</p>
                  {item.link && (
                    <GatsbyLink to={item.link.url} className="button-filled">
                      <span className="button__text">{item.link.title}</span>
                    </GatsbyLink>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </section>
  );
};
