import React, { useState } from "react";
import Modal from "react-modal";
import { GravityForm, gravityFormExtractor } from "../../../gravity-form";
import { useAllGravityForms } from "../../../../hooks";

Modal.defaultStyles.overlay.zIndex = "99";

Modal.setAppElement("#layout");

export const BookNowButton = ({ bookingTitle }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const gravityFormsData = useAllGravityForms();

  const formData = gravityFormExtractor(4, gravityFormsData);

  return (
    <>
      <a onClick={() => setIsOpen(true)} className="button-outlined">
        <span className="button__text">Book now</span>
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="modal__close">
          X
        </button>
        <GravityForm
          formData={formData}
          displayDescription={true}
          dataLayerEvent={"form_submit_success"}
          bookingTitle={bookingTitle}
        />
      </Modal>
    </>
  );
};
