import React from "react";
import "./ListBlock.scss";
import GatsbyLink from "../../GatsbyLink";

export const ListBlock = ({ heading, list, disclaimer }) => {
  return (
    <section className="list-block layout">
      <div className="list-block__wrapper">
        <h2 className="list-block__title">{heading}</h2>
        <ul className="list-block__list">
          {Object.keys(list).length > 0 &&
            list?.map((item, index) => {
              return item.link ? (
                <li key={index}>
                  <GatsbyLink to={item.link.url}> {item.listItem}</GatsbyLink>
                </li>
              ) : (
                <li key={index}>{item.listItem}</li>
              );
            })}
        </ul>
        {disclaimer && (
          <div className="list-block__disclaimer">
            <p>{disclaimer}</p>
          </div>
        )}
      </div>
    </section>
  );
};
