import React from "react";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import { BookNowButton } from "./BookNowButton";
import { Link, animateScroll as scroll } from "react-scroll";
import { slugify } from "../../../utils/helpers";
import "./ServiceBlocks.scss";

export const ServiceBlocks = ({ services }) => {
  return (
    <>
      <section className="service-blocks-navigation layout full-bleed-mobile">
        <div className="service-blocks-navigation__wrapper">
          <nav>
            <ul className="service-blocks-navigation__list">
              {Object.keys(services).length > 0 &&
                services?.map((service, navIndex) => {
                  return (
                    <li
                      key={navIndex}
                      className="service-blocks-navigation__list-item"
                    >
                      <Link
                        to={slugify(service.heading)}
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        {service.heading}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>
      </section>
      <section className="service-blocks-content layout">
        <div className="service-blocks-content__wrapper">
          {Object.keys(services).length > 0 &&
            services?.map((service, contentIndex) => {
              return (
                <div
                  key={contentIndex}
                  className="service-blocks-content__item"
                  id={slugify(service.heading)}
                >
                  <div className="service-blocks-content__item__content">
                    <span className="eyebrow">{service.eyebrow}</span>
                    <h2 className="large-heading">{service.heading}</h2>
                    <RenderContent content={service.content} />
                    <div className="service-blocks-content__buttons">
                      <GatsbyLink to="/contact/" className="button-filled">
                        <span className="button__text">Contact us</span>
                      </GatsbyLink>
                      <BookNowButton
                        bookingTitle={`${service.eyebrow} - ${service.heading}`}
                      />
                    </div>
                  </div>
                  <div className="service-blocks-content__item__image-container">
                    {/* img */}
                    <ImagePass
                      src={service.image}
                      className="service-blocks-content__item__image"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
};
