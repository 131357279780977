import React from "react";
import "./IconBoxes.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import GatsbyLink from "../../GatsbyLink";

const IconBoxContent = ({ iconItem }) => {
  return (
    <>
      <div className="icon-box__icon">
        <ImagePass src={iconItem.icon} lazy={false} />
      </div>
      <p className="icon-box__label">
        {iconItem?.number && (
          <span className="icon-box__number">{iconItem?.number}</span>
        )}
        <RenderContent content={iconItem?.iconLabel} />
      </p>
    </>
  );
};

export const IconBoxes = ({ companyStats, iconBoxes }) => {
  return (
    <section
      className={`icon-boxes layout ${
        companyStats ? "icon-boxes--company" : ""
      }`}
    >
      <div className="icon-boxes__wrapper">
        {Object.keys(iconBoxes).length > 0 &&
          iconBoxes?.map((iconItem, index) => {
            return iconItem.link ? (
              <GatsbyLink
                to={iconItem.link.url}
                className="icon-box"
                key={index}
              >
                <IconBoxContent iconItem={iconItem} />
              </GatsbyLink>
            ) : (
              <div className="icon-box" key={index}>
                <IconBoxContent iconItem={iconItem} />
              </div>
            );
          })}
      </div>
    </section>
  );
};
