import React from "react";
import RenderContent from "../../RenderContent";
import "./ProductSearch.scss";
import { navigate } from "gatsby";

export const ProductSearch = ({ eyebrow, heading, pageHeading }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    const productSearchBarQuery = e.target.productSearch.value;
    if (productSearchBarQuery) {
      navigate(`/product-search?s=${productSearchBarQuery}`, {
        state: {
          productSearchQuery: productSearchBarQuery,
        },
      });
    }
  };

  return (
    <section
      className={`product-search layout ${
        !pageHeading ? "product-search--section" : ""
      }`}
    >
      <div className="product-search__wrapper">
        <span className="eyebrow">{eyebrow}</span>
        <h2
          className={`section-heading ${
            pageHeading ? "large-heading" : "small-heading"
          }`}
        >
          <RenderContent content={heading} />
        </h2>
        <div className="product-search__search-container">
          <span className="product-search__magnify"></span>
          <form
            className="product-search__form"
            onSubmit={(event) => onSubmit(event)}
          >
            <input
              id="productSearch"
              className="product-search__form__input"
              type="text"
              autoComplete="off"
              placeholder="Search for products"
            />
            <button className="product-search__form__button">Search</button>
          </form>
        </div>
      </div>
    </section>
  );
};
