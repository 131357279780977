import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";
import "./ProductCards.scss";

export const ProductCards = ({ title, cards }) => {
  return (
    <section className="product-cards layout">
      <div className="product-cards__wrapper">
        <h2 className="product-cards__title">{title}</h2>
      </div>
      <div className="product-cards__grid">
        {Object.keys(cards).length > 0 &&
          cards?.map((card, index) => {
            return card?.page && card?.page?.url ? (
              <GatsbyLink
                to={card?.page?.url}
                className={`product-card ${
                  card.uppercase ? "product-card--uppercase" : ""
                }`}
                key={index}
              >
                <span className="product-card__title">{card?.title}</span>
                <span className="product-card__intro">{card?.intro}</span>
                <span className="product-card__button">
                  {card?.linkText === "find" ? "Find out more" : "View"}
                </span>
                {card?.image && (
                  <ImagePass
                    src={card?.image}
                    className="product-card__image"
                  />
                )}
              </GatsbyLink>
            ) : (
              <div
                className={`product-card ${
                  card.uppercase ? "product-card--uppercase" : ""
                }`}
                key={index}
              >
                <span className="product-card__title">{card?.title}</span>
                <span className="product-card__intro">{card?.intro}</span>                

                {card?.image && (
                  <ImagePass
                    src={card?.image}
                    className="product-card__image"
                  />
                )}
              </div>
            );
          })}
      </div>
    </section>
  );
};
