import React from "react";
import "./UspCallout.scss";
import { ImagePass } from "../../Image/ImagePass";

export const UspCallout = ({ heading, usps }) => {
  return (
    <section className="usp-callout layout">
      <div className="usp-callout__wrapper">
        <h2 className="usp-callout__title">{heading}</h2>
        <div className="usp-callout__grid">
          {Object.keys(usps).length > 0 &&
            usps?.map((usp, index) => {
              return (
                <div className="usp-callout__item" key={index}>
                  <div className="usp-callout__icon">
                    <ImagePass src={usp?.icon} />
                  </div>
                  <p className="usp-callout__text">{usp?.heading}</p>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
