import React from "react";
import "./LogoFeed.scss";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";

export const LogoFeed = ({ title, logos }) => {
  return (
    <section className="logo-feed layout">
      <div className="logo-feed__wrapper">
        <p className="heading">{title}</p>
        <div className="logo-gallery">
          {Object.keys(logos).length > 0 &&
            logos?.map((item, index) => {
              return item.link ? (
                <GatsbyLink
                  to={item.link.url}
                  className="logo-gallery__item"
                  key={index}
                >
                  <ImagePass src={item?.logo} />
                </GatsbyLink>
              ) : (
                <div key={index} className="logo-gallery__item">
                  <ImagePass src={item?.logo} />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
