import React from "react";
import "./TwoUpCards.scss";
import GatsbyLink from "../../GatsbyLink";

export const TwoUpCards = ({ cards }) => {
  return (
    <section className="two-up-cards layout">
      <div className="two-up-cards__wrapper">
        {Object.keys(cards).length > 0 &&
          cards?.map((card, index) => {
            return (
              <div className="two-up-cards__card" key={index}>
                <span className="eyebrow two-up-cards__eyebrow">
                  {card?.eyebrow}
                </span>
                <p className="two-up-cards__heading">{card?.heading}</p>
                <GatsbyLink to={card?.link?.url} className="button-filled">
                  <span className="button__text">{card?.link?.title}</span>
                </GatsbyLink>
              </div>
            );
          })}
      </div>
    </section>
  );
};
