import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./RelatedCaseStudies.scss";
import { Carousel } from "../../Carousel";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";

const RelatedCasestudiesComp = ({
  data: {
    allWordpressWpCaseStudies: { edges: caseStudies },
  },
}) => {
  const swiperSettings = {
    id: "text-card-carousel",
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: false,
    clickable: "false",
    navgiation: true,
    pagination: {
      clickable: true,
    },
    navigation: true,
  };

  return (
    <section className="related-case-studies layout">
      <div className="related-case-studies__wrapper">
        <h2 className="related-case-studies__title">Recent Case Studies</h2>
        <Carousel swiperSettings={swiperSettings}>
          {Object.keys(caseStudies).length > 0 &&
            caseStudies?.map((study, index) => {
              return (
                <div className="case-study" key={index}>
                  <div className="case-study__content">
                    <span className="eyebrow case-study__content__eyebrow">
                      Case study
                    </span>
                    <span className="case-study__content__heading">
                      {study?.node?.title}
                    </span>
                    <RenderContent content={study?.node?.excerpt} />
                    <GatsbyLink
                      to={study?.node?.path}
                      className="button-filled"
                    >
                      <span className="button__text">Read More</span>
                    </GatsbyLink>
                  </div>
                  <div className="case-study__image">
                    <ImagePass src={study?.node?.featured_media} />
                  </div>
                </div>
              );
            })}
        </Carousel>
      </div>
    </section>
  );
};

const RelatedCasestudies = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          allWordpressWpCaseStudies(
            sort: { fields: date, order: DESC }
            filter: { slug: { ne: "gatsby-demo" } }
            limit: 3
          ) {
            edges {
              node {
                ...CaseStudyListFields
              }
            }
          }
        }
      `}
      render={(data) => <RelatedCasestudiesComp {...props} data={data} />}
    />
  );
};

export default RelatedCasestudies;
