import React from "react";
import "./TextCardSlider.scss";
import { Carousel } from "../../Carousel";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";

export const TextCardSlider = ({ cards, content, link }) => {
  const swiperSettings = {
    id: "text-card-carousel",
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: false,
    clickable: "false",
    navgiation: true,
    pagination: {
      clickable: true,
    },
    navigation: true,
    breakpoints: {
      760: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <section className="text-card-slider layout">
      <div className="text-card-slider__wrapper">
        <div className="text-card-slider__content">
          <RenderContent content={content} />
          {link && (
            <GatsbyLink to={link.url} className="button-filled">
              <span className="button__text">{link.title}</span>
            </GatsbyLink>
          )}
        </div>
        <div className="text-card-slider__carousel">
          <Carousel swiperSettings={swiperSettings}>
            {Object.keys(cards).length > 0 &&
              cards?.map((card, index) => {
                return (
                  <GatsbyLink
                    to={card?.link?.url}
                    className="text-card-slider__card"
                    key={index}
                  >
                    <div className="text-card-slider__card__icon">
                      <ImagePass src={card.icon} lazy={false} />
                    </div>
                    <p className="text-card-slider__card__title">
                      {card.title}
                    </p>
                    <p className="text-card-slider__card__content">
                      {card.content}
                    </p>
                    <span className="button-filled">
                      <span className="button__text">Learn more</span>
                    </span>
                  </GatsbyLink>
                );
              })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};
