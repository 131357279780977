import React, { useCallback, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { decodeEntities } from "../../../utils/helpers";
import pinIcon from "../../../images/map-pin.svg";
const mapStyles = require("./mapSettings.json");
import "./MapBlock.scss";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

export const MapBlockComponent = ({
  displayMaps,
  data: {
    siteSettings: {
      options: { locations },
    },
  },
}) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: -32.12236784608835,
    lng: 115.83997749206885,
  };

  const coordinates = [
    // Perth
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.713225878434!2d115.88710985145977!3d-31.995788631626265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bc8369178bed%3A0x55ab93a58152d4ea!2sAquip%20Systems!5e0!3m2!1sen!2sau!4v1629331158163!5m2!1sen!2sau",
    // Sydney
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.5631794500905!2d151.29979725149676!3d-33.69437331736505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d54e9b88bab23%3A0x3e0b6238c4cfbaa9!2sAquip%20Systems%20Pty%20Ltd.!5e0!3m2!1sen!2sau!4v1629331105689!5m2!1sen!2sau",
    // { lat: -33.694216224056, lng: 151.30212964497235 },
    // Melbourne
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.049238296551!2d145.2932721515922!3d-37.835733142975826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad63b217db1208b%3A0xe1c0915e638eda27!2s40%20Kalman%20Dr%2C%20Boronia%20VIC%203155!5e0!3m2!1sen!2sau!4v1629331291583!5m2!1sen!2sau",
    // {
    //   lat: -37.83555096602777,
    //   lng: 145.29550911404644,
    // },
    // Queensland
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5101.366172583772!2d153.40238720008443!3d-28.062565201459016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9103580ebab7a9%3A0x65b10f81bc34f935!2sAquip%20Flowservices%20Pty%20Ltd!5e0!3m2!1sen!2sau!4v1632371603066!5m2!1sen!2sau",
    // { lat: -28.063629067423125, lng: 153.40469286970313 },
  ];

  const [map, setMap] = React.useState(null);
  const [zoom, setZoom] = React.useState(16);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <section className="map-block layout">
      <div className="map-block__wrapper">
        {Object.keys(locations).length > 0 &&
          locations?.map((location, index) => {
            return (
              <div key={index}>
                <h3 className="map-block__location-name">
                  {location?.locationName}
                </h3>
                <address className="map-block__location-address">
                  {location?.addressStreet}
                  <br />
                  {location?.addressCity}, {location?.addressRegion}{" "}
                  {location?.addressPostCode}
                </address>
                <a
                  href={`tel:${location?.phone.replace(/[^A-Z0-9]/gi, "")}`}
                  className="map-block__location-phone"
                  aria-label="Call Now"
                >
                  {location?.phone}
                </a>
                <a
                  href={`mailto:${decodeEntities(location?.email)}`}
                  className="map-block__location-email"
                >
                  {decodeEntities(location?.email)}
                </a>
                {displayMaps && (
                  <div className="map-block__location-map">
                    <iframe
                      src={coordinates[index]}
                      width="680"
                      height="500"
                      allowFullScreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </section>
  );
};

export function MapBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showSearchIconInMainNavigation
              locations {
                locationName
                addressStreet
                addressCity
                addressRegion
                addressPostCode
                phone
                email
              }
            }
          }
        }
      `}
      render={(data) => <MapBlockComponent data={data} {...props} />}
    />
  );
}
