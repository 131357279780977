import React from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./StaffProfiles.scss";

export const StaffProfiles = ({ staffProfiles }) => {
  return (
    <section className="staff-profiles layout">
      <div className="staff-profiles__wrapper">
        {Object.keys(staffProfiles).length > 0 &&
          staffProfiles?.map((profile, index) => {
            return (
              <div className="staff-profile" key={index}>
                <div className="staff-profile__image">
                  <ImagePass src={profile.image} />
                </div>
                <span className="staff-profile__position">
                  {profile.position}
                </span>
                <p className="staff-profile__name">{profile.name}</p>
              </div>
            );
          })}
      </div>
    </section>
  );
};
